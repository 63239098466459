<template>
  <v-dialog v-model="delete_alert_modal" width="550">
    <v-card class="pa-0">
      <v-alert prominent text type="error" class="pa-3 mb-0">
        <v-card-title class="text-h5">
          You can't delete this ledger account
        </v-card-title>
        <v-card-text>
          <span v-for="item in delete_alert_message" :key="item.id"
            >{{ item }} <br
          /></span>
        </v-card-text>
      </v-alert>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "LedgerAccountsDeleteDisableDialog",
  props: ["delete_alert_modal", "delete_alert_message"],
  data() {
    return {};
  },
};
</script>
